import React, { useCallback } from "react"
import styles from "./MenuItemUser.module.scss"
import { ClassProps } from "../../utility/common/props"
import { User } from "../../models/user"
import { useDispatch } from "react-redux"
import cn from "classnames"

import UserCard from "../UserCard/UserCard"
import { useTranslation } from "react-i18next"
import MenuItemRow from "../MenuItemRow/MenuItemRow"
import MenuIcon from "../MenuIcon/MenuIcon"
import SidebarContextMenu from "../SidebarContextMenu/SidebarContextMenu"
import { actions } from "../../store/notifications/slice"
import translation from "../../locales/notification"
import { selectNewsStackNotifications } from "../../store/notifications/selectors"
import { unsubscribeFromUsersData } from "../../store/userOperator/thunks"
import { redirectToLogout } from "../../utility/auth/credentialHelper"
import { definedMenuItems } from "../../utility/menu/definedMenuItems"
import Indicator from "../Indicator/Indicator"
import { saveStatusTimer } from "../../store/userOperator/actions"
import { useAppSelector } from "../../store/hooks"
import { selectOperatorStatusState } from "../../store/userOperator/selectors"
import { selectors } from "../../utility/common/dataSelectors"

const tNamespace = "project:"
interface MenuItemUserProps extends ClassProps {
    user: User
    selectMenu?: (menuItemId: string) => void
    getSelectedMenu: (menuId: string) => boolean
    inHeader?: boolean
}

const MenuItemUser: React.FC<MenuItemUserProps> = props => {
    const { user, selectMenu, getSelectedMenu, className, inHeader } = props
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const newsStack = useAppSelector(selectNewsStackNotifications)
    const status = useAppSelector(selectOperatorStatusState)

    const isUserSelected = getSelectedMenu(definedMenuItems.Profile.id) || getSelectedMenu(definedMenuItems.LogOut.id)

    const memoToggleNotificationList = useCallback(() => dispatch(actions.toggleNotificationsList()), [dispatch])

    const handleLogout = () => {
        if (status.data) {
            const time = Number(document.querySelector<HTMLElement>(selectors.statusTimer)?.dataset.statusTimer)

            if (time) {
                saveStatusTimer(status.data, time)
            }
        }

        dispatch(unsubscribeFromUsersData())
        redirectToLogout()
    }

    return (
        <SidebarContextMenu
            placement={`${inHeader ? "bottom" : "top"}-start`}
            items={
                <>
                    <MenuItemRow
                        icon={<MenuIcon menuId={definedMenuItems.Profile.id} className="menu-item-user__menu-icon" />}
                        title={t(`${tNamespace}${definedMenuItems.Profile.title}`)}
                        onClick={() => selectMenu && selectMenu(definedMenuItems.Profile.id)}
                        selected={getSelectedMenu(definedMenuItems.Profile.id)}
                        testId={definedMenuItems.Profile.id}
                    />
                    <MenuItemRow
                        icon={<MenuIcon menuId={definedMenuItems.NotificationList.id} />}
                        title={t(translation.menuTitle)}
                        onClick={memoToggleNotificationList}
                        testId={definedMenuItems.NotificationList.id}
                    />
                    <MenuItemRow
                        icon={<MenuIcon menuId={definedMenuItems.LogOut.id} />}
                        title={t(`${tNamespace}${definedMenuItems.LogOut.title}`)}
                        onClick={handleLogout}
                        selected={false}
                        testId={definedMenuItems.LogOut.id}
                    />
                </>
            }
            className={styles.menuItemUser__context}
            containerClassName={cn(styles.menuItemUser, className)}
        >
            <button className={styles.menuItemUser__toggle}>
                <UserCard
                    className={cn(!inHeader && styles.menuItemUser__card, isUserSelected && "selected")}
                    firstName={user.FirstName}
                    lastName={user.LastName}
                    picture={user.Picture}
                    indicator={
                        <Indicator className={styles.menuItemUser__indicatorBorder}>{newsStack.length}</Indicator>
                    }
                    isShort={!inHeader}
                    size={inHeader ? "sm" : "def"}
                />
            </button>
        </SidebarContextMenu>
    )
}

export default MenuItemUser
