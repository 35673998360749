import {
    Article,
    ArticleEditFormValues,
    ArticleKind,
    ArticleShareFormValues,
    ArticleStatusType,
    EArticleEditFormChangesInheritance,
    SearchArticleHighlights
} from "../../models/article"
import { Category } from "../../models/category"
import {
    KnowledgeBaseSubscriptionSelectItem,
    KnowledgeBaseSubscriptionType,
    KnowledgeBaseSubscriptionValue
} from "../../models/knowledgeBase/subscription"
import { TFunction } from "react-i18next"
import {
    KnowledgeBaseAccessType,
    KnowledgeBasePermission,
    KnowledgeBasePermissionWeight,
    KnowledgeBasePermittedAction,
    KnowledgeBasePermittedActionSelectItem
} from "../../models/knowledgeBase/permission"
import { UserAccessPrimitive } from "../../models/knowledgeBase/userAccess"
import { isModifyPermitted } from "./useModifyPermitted"
import AsyncState from "../../core/asyncState"
import { ItemId, TreeData } from "@atlaskit/tree"

const tKnowledgeBaseSubscriptionOptions = "knowledgeBase:subscription:options:"
const tKnowledgeBasePermissionOptions = "knowledgeBase:permissions:options:"

export const ROOT_CATEGORY_CODE = "root"

export const branchToString = (branch: Category[], separator: string, untitled: string) =>
    branch.map(category => category.Title || untitled).join(` ${separator} `)

const replaceBranchCategory = (branch: Category[], category: Category, replaceableSymbolCode: string) => {
    const branchCategoryIndex = branch.findIndex(c => c.SymbolCode === replaceableSymbolCode)
    if (branchCategoryIndex !== -1) branch[branchCategoryIndex] = category
}

export const replaceBranchCategoryBySymbolCode = (branch: Category[], category: Category) => {
    replaceBranchCategory(branch, category, category.SymbolCode)
}

export const categorySelected = (branch: Category[], category: Category) => {
    return category.Id === branch[branch.length - 1].Id
}

export const mapArticleStatusToClassName = (status: ArticleStatusType) => {
    switch (status) {
        case ArticleStatusType.Active:
            return "article-status_active"
        case ArticleStatusType.Inactive:
            return "article-status_inactive"
        case ArticleStatusType.Removed:
            return "article-status_removed"
        case ArticleStatusType.Draft:
            return "article-status_draft"
        case ArticleStatusType.Approval:
            return "article-status_approval"
        default:
            return "article-status_draft"
    }
}

export const getValuesFromArticle = (article: Article): ArticleEditFormValues => {
    return {
        Title: article.Title,
        Kind: article.Kind,
        Type: article.Type || "",
        Tags: article.Tags.length ? `#${article.Tags.join(" #")}` : "",
        Parameters: article.Parameters.map((p, index) => ({
            ...p,
            Id: `${index}`
        })),
        Expanded: article.Expanded
    }
}

export const getDefaultValuesFromArticle = (): ArticleEditFormValues => {
    return {
        Title: "",
        Kind: ArticleKind.Common,
        Type: "",
        Tags: "",
        Parameters: [],
        Expanded: false
    }
}

export const getDefaultArticleShareFormValues = (
    permissions: KnowledgeBasePermission[],
    withNested: boolean
): ArticleShareFormValues => ({
    ShareLink: "",
    Permissions: permissions,
    ChangesInheritance: withNested
        ? EArticleEditFormChangesInheritance.INHERIT
        : EArticleEditFormChangesInheritance.ROOT
})

export const putHighlightsIntoArticleTitleAndText = (
    title: string,
    text: string,
    highlights?: SearchArticleHighlights
) => {
    try {
        if (!highlights) return [title, text]

        let titleWithHighlights = title
        let textWithHighlights = text

        const titleHighlights = highlights["title"]
        if (titleHighlights && titleHighlights.length > 0) {
            const firstHighlight = titleHighlights[0]
            const pureTitle = firstHighlight.replace(/<\/?em[^>]*>/g, "")
            const pureTitleRegExp = new RegExp(pureTitle, "g")
            titleWithHighlights = title.replace(pureTitleRegExp, firstHighlight)
        }

        const textHighlights = highlights["answers.answer"]
        if (textHighlights && textHighlights.length > 0) textWithHighlights = textHighlights[0]

        return [titleWithHighlights, textWithHighlights]
    } catch (e) {
        console.error(e)
        return [title, text]
    }
}

export const getSubscriptionOptions = (t: TFunction): KnowledgeBaseSubscriptionSelectItem[] => [
    {
        Type: KnowledgeBaseSubscriptionType.Notify,
        Title: t(`${tKnowledgeBaseSubscriptionOptions}notify`)
    },
    {
        Type: KnowledgeBaseSubscriptionType.WithConfirmation,
        Title: t(`${tKnowledgeBaseSubscriptionOptions}with-confirmation`)
    }
]

export const getPermittedActionOptions = (t: TFunction): KnowledgeBasePermittedActionSelectItem[] => [
    {
        Action: KnowledgeBasePermittedAction.View,
        Title: t(`${tKnowledgeBasePermissionOptions}view`)
    },
    {
        Action: KnowledgeBasePermittedAction.Edit,
        Title: t(`${tKnowledgeBasePermissionOptions}edit`)
    }
]

export const isKnowledgeBaseSubscription = (
    accessItem: UserAccessPrimitive
): accessItem is KnowledgeBaseSubscriptionValue => accessItem.hasOwnProperty("SubscriptionType")

export const isSameUserAccessItem = (arrayItem: UserAccessPrimitive, inputItem: UserAccessPrimitive) =>
    arrayItem.Value === inputItem.Value && arrayItem.ProjectId === inputItem.ProjectId

export const getUserAccessString = (subscription: UserAccessPrimitive) =>
    `${subscription.Type}/${subscription.Value}/${subscription.ProjectId}`

const statusWithActionPredicate = (status: ArticleStatusType, action: KnowledgeBasePermittedAction) =>
    (action === KnowledgeBasePermittedAction.View && status === ArticleStatusType.Active) || isModifyPermitted(action)

const permissionValuePredicate = (permission: KnowledgeBasePermission, login: string, roleId?: string) =>
    permission.Value === login ||
    (roleId && permission.Value === roleId) ||
    permission.Type === KnowledgeBaseAccessType.All

export const getArticlePermittedAction = (
    articlePermissions: KnowledgeBasePermission[],
    articleStatus: ArticleStatusType,
    projectId: string,
    login: string,
    roleId?: string
): KnowledgeBasePermittedAction | undefined => {
    const permission = articlePermissions.find(
        p => projectId === p.ProjectId && permissionValuePredicate(p, login, roleId)
    )
    return permission && statusWithActionPredicate(articleStatus, permission.Action) ? permission.Action : undefined
}

export const permissionWeightMapper = (type: KnowledgeBaseAccessType, action: KnowledgeBasePermittedAction) => {
    return KnowledgeBasePermissionWeight[(type + action) as keyof typeof KnowledgeBasePermissionWeight]
}

export const isDefaultArticleReceived = (defaultArticleState: AsyncState<string | null>) =>
    defaultArticleState.data || defaultArticleState.data === null || defaultArticleState.error

const recursiveRemoveItem = (tree: TreeData, itemsToRemoveIds: ItemId[]): TreeData => {
    if (!itemsToRemoveIds.length) {
        return tree
    }
    const newItemsToRemove: ItemId[] = []

    Object.entries(tree.items).forEach(([itemId, itemData]) => {
        if (itemsToRemoveIds.includes(itemId)) {
            newItemsToRemove.push(...itemData.children)
            delete tree.items[itemId]
        }
    })
    recursiveRemoveItem(tree, newItemsToRemove)

    return tree
}

export const removeItemFromParentChildren = (tree: TreeData, removeItemId: ItemId): TreeData => {
    Object.entries(tree.items).forEach(([_, itemData]) => {
        const foundChildrenIndex = itemData.children.findIndex(child => child === removeItemId)
        if (foundChildrenIndex !== -1) {
            itemData.children.splice(foundChildrenIndex, 1)
        }
    })

    return tree
}

export const cascadeRemoveFromTree = (tree: TreeData, removeItemId: ItemId): TreeData => {
    if (!(removeItemId in tree.items)) {
        return tree
    }

    removeItemFromParentChildren(tree, removeItemId)
    tree = recursiveRemoveItem(tree, [removeItemId])

    return tree
}
