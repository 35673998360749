import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import AsyncState from "../../core/asyncState"
import { SystemError } from "../../core/error"
import { Category, CategoryMutation, GetRelatedCategoriesResponse, MoveCategoryResponse } from "../../models/category"
import { Catalog } from "../../models/catalog"
import {
    Article,
    ArticleAnswer,
    ArticleAnswersFormValues,
    ArticleQuestion,
    ArticleStatusType,
    ArticleView,
    MarkedMessagesReserve,
    SampleQuestionType,
    SearchArticlesHighlights,
    SearchArticlesScores
} from "../../models/article"
import { ArticleWorkMode, KnowledgeBaseWorkMode } from "../../models/knowledgeBase/workMode"
import {
    cascadeRemoveFromTree,
    getArticlePermittedAction,
    removeItemFromParentChildren
} from "../../utility/knowledgeBase/knowledgeBase"
import { ArticleType, GetArticleTypesResponse } from "../../models/articleType"
import { ArticleFilterFormValues } from "../../models/articleFilter"
import { ArticleUserViews } from "../../models/articleUserViews"
import { KnowledgeBasePermittedAction } from "../../models/knowledgeBase/permission"
import { toManualSampleQuestion } from "../../utility/knowledgeBase/sampleQuestions"
import { mutateTree, TreeData } from "@atlaskit/tree"
import {
    addChildrenCategories,
    categoryMutationToTreeItemMutation,
    convertCatalogToTree,
    convertCategoryToTreeItem,
    convertFavoriteArticleToTree,
    createTreeItemFromCategoryMutation,
    insertTreeItem,
    mutateTreeItem,
    removeCategoryFromCatalog,
    updateRelatedCategories
} from "../../utility/knowledgeBase/categoriesTree"
import { ItemId, TreeItem } from "@atlaskit/tree/types"
import { ArticleCurrentBranch } from "../../models/articleCurrentBranch"
import { ArticleRelatedCategories } from "../../models/articleRelatedCategories"
import { SharedType } from "../../models/sharing"
import { convertItemsToTreesMap } from "../../utility/common/tree"
import { resetReducerState } from "../action"
import { FavoriteArticlesBlock } from "../../models/articleFavoriteBlock"
import { ArticleCompareMode, ArticleCompareUpdateState } from "../../models/articleCompare"
import { TreeItemMutation } from "@atlaskit/tree/dist/types/utils/tree"
import { ArticleActionButtonsActions } from "../../components/ArticleActionButtons/hooks/useDefaultActions"

export type CurrentArticleState = {
    Article?: Article
    PermittedAction?: KnowledgeBasePermittedAction
    IsLocked?: boolean
}

export type KnowledgeBaseState = Readonly<{
    currentBranch: ArticleCurrentBranch
    relatedCategories: AsyncState<ArticleRelatedCategories>
    workMode: {
        knowledgeBase: KnowledgeBaseWorkMode
        article: ArticleWorkMode
    }
    currentArticle: AsyncState<CurrentArticleState>
    oldArticleVersion?: CurrentArticleState
    articleUserViews: AsyncState<ArticleUserViews>
    searchArticles: AsyncState<SearchArticlesSuccessPayload>
    currentArticlesFilter: ArticleFilterFormValues
    updateArticle: AsyncState<string>
    shareArticle: AsyncState<string | undefined>
    removeArticleSharing: AsyncState<void>
    getCatalogs: AsyncState<ItemId[]>
    catalogsMap: Record<string, TreeData>
    createCategory: AsyncState<string>
    moveCategory: AsyncState<ItemId>
    createCatalog: AsyncState<Catalog>
    updateCatalog: AsyncState<Catalog>
    deleteCatalog: AsyncState<string>
    shareCatalog: AsyncState<string | undefined>
    articleTypes: AsyncState<GetArticleTypesResponse>
    createArticleType: AsyncState<void>
    updateArticleType: AsyncState<void>
    deleteArticleType: AsyncState<void>
    answersFrom: ArticleAnswersFormValues
    currentAnswer?: ArticleAnswer
    formQuestions: SampleQuestionType[]
    markedMessages: SampleQuestionType[]
    markedMessagesCount: number
    markedMessagesReserve: MarkedMessagesReserve
    sharedType: AsyncState<SharedType>
    defaultArticle: AsyncState<string | boolean>
    editedArticleExtId: string
    editedArticleSurvey: string[]
    articleExpanded: boolean
    articleFavorite: AsyncState<boolean>
    articleFavoriteBlock: AsyncState<TreeData>
    articleFavoriteBlockExpanded: boolean
    moveFavoriteArticle: AsyncState<void>
    isArticleHistoryMod: boolean
    articleActionButtonsActions?: ArticleActionButtonsActions
    articleCompareMode: ArticleCompareMode
    previousVersionArticleId?: string
}>

const defaultAnswerForm = {
    Answers: []
}

const initialState: KnowledgeBaseState = {
    currentBranch: {},
    relatedCategories: AsyncState.create(),
    workMode: {
        knowledgeBase: KnowledgeBaseWorkMode.CatalogsView,
        article: ArticleWorkMode.View
    },
    currentArticle: AsyncState.create(),
    articleUserViews: AsyncState.create(),
    searchArticles: AsyncState.create(),
    currentArticlesFilter: {},
    updateArticle: AsyncState.create(),
    shareArticle: AsyncState.create(),
    removeArticleSharing: AsyncState.create(),
    getCatalogs: AsyncState.create(),
    catalogsMap: {},
    createCategory: AsyncState.create(),
    moveCategory: AsyncState.create(),
    createCatalog: AsyncState.create(),
    updateCatalog: AsyncState.create(),
    deleteCatalog: AsyncState.create(),
    shareCatalog: AsyncState.create(),
    articleTypes: AsyncState.create(),
    createArticleType: AsyncState.create(),
    updateArticleType: AsyncState.create(),
    deleteArticleType: AsyncState.create(),
    answersFrom: defaultAnswerForm,
    formQuestions: [],
    markedMessages: [],
    markedMessagesCount: 0,
    markedMessagesReserve: {
        Questions: [],
        Count: 0,
        RemovedIds: []
    },
    sharedType: AsyncState.create(),
    defaultArticle: AsyncState.create(),
    editedArticleExtId: "",
    editedArticleSurvey: [],
    articleExpanded: false,
    articleFavorite: AsyncState.create(),
    articleFavoriteBlock: AsyncState.create(),
    articleFavoriteBlockExpanded: true,
    moveFavoriteArticle: AsyncState.create(),
    articleCompareMode: ArticleCompareMode.Default,
    isArticleHistoryMod: false
}

interface GetCategoriesProcessPayload {
    CatalogId: ItemId
    ParentCategoryId: ItemId
}

interface GetCategoriesSuccessPayload extends GetCategoriesProcessPayload {
    Categories: Category[]
}

interface RemoveCategoryFromCatalogSuccessPayload extends GetCategoriesProcessPayload {
    CategoryId: string
}

interface GetCategoriesFailedPayload extends GetCategoriesProcessPayload {
    Error: SystemError
}

interface ToggleCategoryPayload {
    CatalogId: ItemId
    CategoryId: ItemId
    IsExpanded: boolean
}

interface AddCategoryPayload {
    ParentItemId: string
    Category: Category
}

interface SearchArticlesSuccessPayload {
    Articles: ArticleView[]
    Highlights?: SearchArticlesHighlights
    Scores?: SearchArticlesScores
    Total?: number
}

interface GetArticleSuccessPayload {
    Article?: Article
    PermittedAction?: KnowledgeBasePermittedAction
    Login?: string
    RoleId?: string
    ProjectId?: string
    IsLocked?: boolean
}

interface RelatedCategoriesMutation {
    CategoryId?: string
    ParentCategory?: TreeItem
    ChildCategories?: TreeItem[]
}

const knowledgeBase = createSlice({
    name: "knowledgeBase",
    initialState,
    reducers: {
        mutateCurrentBranch(state, action: PayloadAction<Partial<ArticleCurrentBranch>>) {
            state.currentBranch = { ...state.currentBranch, ...action.payload }
        },
        clearCurrentBranch(state) {
            state.currentBranch = {}
        },
        clearCurrentArticle(state) {
            state.currentArticle = initialState.currentArticle
        },
        setKnowledgeBaseWorkMode(state, action: PayloadAction<KnowledgeBaseWorkMode>) {
            state.workMode.knowledgeBase = action.payload
        },
        setArticleLockStatus(state, action: PayloadAction<boolean>) {
            const currArticleData = state.currentArticle.data

            if (currArticleData) {
                const { Article, PermittedAction } = currArticleData

                state.currentArticle = state.currentArticle.toSuccess({
                    Article,
                    PermittedAction,
                    IsLocked: action.payload
                })
            }
        },
        setArticleWorkMode(state, action: PayloadAction<ArticleWorkMode>) {
            state.workMode.article = action.payload
        },
        getArticleProcess(state) {
            state.currentArticle = state.currentArticle.toProcess()
        },
        getArticleSuccess(state, action: PayloadAction<GetArticleSuccessPayload>) {
            const { Article, PermittedAction, Login, RoleId, ProjectId, IsLocked } = action.payload
            const permittedAction =
                PermittedAction ??
                (Article && Login && ProjectId
                    ? getArticlePermittedAction(Article.Permissions, Article.Status, ProjectId, Login, RoleId)
                    : undefined)
            if (permittedAction) {
                state.currentArticle = state.currentArticle.toSuccess({
                    Article: Article,
                    PermittedAction: permittedAction,
                    IsLocked: IsLocked
                })
                state.answersFrom = { Answers: Article?.Answers ?? [] }
            } else {
                state.currentArticle = state.currentArticle.toSuccess({
                    Article: undefined,
                    PermittedAction: permittedAction,
                    IsLocked: IsLocked
                })
                state.answersFrom = defaultAnswerForm
            }
            state.currentAnswer = undefined
        },
        getSharedArticleSuccess(state, action: PayloadAction<GetArticleSuccessPayload>) {
            const { Article } = action.payload
            state.currentArticle = state.currentArticle.toSuccess({
                Article
            })
            state.answersFrom = { Answers: Article?.Answers ?? [] }
            state.currentAnswer = Article?.Answers?.[0]
        },
        getArticleFailed(state, action: PayloadAction<SystemError>) {
            state.currentArticle = state.currentArticle.toFailed(action.payload)
        },
        searchArticlesProcess(state) {
            state.searchArticles = state.searchArticles.toProcess()
        },
        searchArticlesSuccess(state, action: PayloadAction<SearchArticlesSuccessPayload>) {
            state.searchArticles = state.searchArticles.toSuccess(action.payload)
        },
        searchArticlesFailed(state, action: PayloadAction<SystemError>) {
            state.searchArticles = state.searchArticles.toFailed(action.payload)
        },
        updateArticleProcess(state) {
            state.updateArticle = state.updateArticle.toProcess()
        },
        updateArticleSuccess(state, action: PayloadAction<string>) {
            state.updateArticle = state.updateArticle.toSuccess(action.payload)
        },
        updateArticleFailed(state, action: PayloadAction<SystemError>) {
            state.updateArticle = state.updateArticle.toFailed(action.payload)
        },
        shareArticleBeforeProcess(state) {
            state.shareArticle = state.shareArticle.toBeforeProcess()
        },
        shareArticleProcess(state) {
            state.shareArticle = state.shareArticle.toProcess()
        },
        shareArticleSuccess(state, action: PayloadAction<string | undefined>) {
            state.shareArticle = state.shareArticle.toSuccess(action.payload)
        },
        shareArticleFailed(state, action: PayloadAction<SystemError>) {
            state.shareArticle = state.shareArticle.toFailed(action.payload)
        },
        getDefaultArticleProcess(state) {
            state.defaultArticle = state.defaultArticle.toProcess()
        },
        getDefaultArticleSuccess(state, action: PayloadAction<string | false>) {
            state.defaultArticle = state.defaultArticle.toSuccess(action.payload)
        },
        getDefaultArticleFailed(state, action: PayloadAction<SystemError>) {
            state.defaultArticle = state.defaultArticle.toFailed(action.payload)
        },
        removeArticleSharingProcess(state) {
            state.removeArticleSharing = state.removeArticleSharing.toProcess()
        },
        removeArticleSharingSuccess(state) {
            state.removeArticleSharing = state.removeArticleSharing.toSuccess()
            state.shareArticle = state.shareArticle.toSuccess(undefined)
        },
        removeArticleSharingFailed(state, action: PayloadAction<SystemError>) {
            state.removeArticleSharing = state.removeArticleSharing.toFailed(action.payload)
        },
        removeArticleCategories(state, action: PayloadAction<{ CatalogCode: string; CategoryId: string }>) {
            const { CatalogCode, CategoryId } = action.payload
            state.catalogsMap[CatalogCode] = cascadeRemoveFromTree(state.catalogsMap[CatalogCode], CategoryId)
        },
        getCatalogsProcess(state) {
            state.getCatalogs = state.getCatalogs.toProcess()
        },
        getCatalogsSuccess(state, action: PayloadAction<Catalog[]>) {
            const { treesMap, trees } = convertItemsToTreesMap(action.payload, convertCatalogToTree)
            state.getCatalogs = state.getCatalogs.toSuccess(trees.map(t => t.rootId))
            state.catalogsMap = treesMap
        },
        updateCatalogsMapItem(
            state,
            action: PayloadAction<{
                catalogId: string
                treeItemId: ItemId
                item: TreeItem
            }>
        ) {
            if (
                action.payload.catalogId in state.catalogsMap &&
                action.payload.treeItemId in state.catalogsMap[action.payload.catalogId].items
            ) {
                state.catalogsMap[action.payload.catalogId].items[action.payload.treeItemId] = action.payload.item
            }
        },
        getCatalogsFailed(state, action: PayloadAction<SystemError>) {
            state.getCatalogs = state.getCatalogs.toFailed(action.payload)
        },
        getCategoriesProcess(state, action: PayloadAction<GetCategoriesProcessPayload>) {
            const { CatalogId, ParentCategoryId } = action.payload
            state.catalogsMap[CatalogId] = mutateTree(state.catalogsMap[CatalogId], ParentCategoryId, {
                isChildrenLoading: true
            })
        },
        getCategoriesSuccess(state, action: PayloadAction<GetCategoriesSuccessPayload>) {
            const { CatalogId, ParentCategoryId, Categories } = action.payload
            state.catalogsMap[CatalogId] = addChildrenCategories(
                ParentCategoryId,
                state.catalogsMap[CatalogId],
                Categories
            )
        },
        removeCategoryFromCatalogSuccess(state, action: PayloadAction<RemoveCategoryFromCatalogSuccessPayload>) {
            const { CatalogId, ParentCategoryId, CategoryId } = action.payload
            state.catalogsMap[CatalogId] = removeCategoryFromCatalog(
                state.catalogsMap[CatalogId],
                CategoryId,
                ParentCategoryId as string
            )
        },
        getCategoriesFailed(state, action: PayloadAction<GetCategoriesFailedPayload>) {
            const { CatalogId, ParentCategoryId } = action.payload
            state.catalogsMap[CatalogId] = mutateTree(state.catalogsMap[CatalogId], ParentCategoryId, {
                isChildrenLoading: false
            })
        },
        getRelatedCategoriesProcess(state) {
            state.relatedCategories = state.relatedCategories.toProcess()
        },
        getRelatedCategoriesSuccess(state, action: PayloadAction<GetRelatedCategoriesResponse>) {
            const { Id, ParentCategory, ChildCategories } = action.payload

            state.relatedCategories = state.relatedCategories.toSuccess({
                CategoryId: Id,
                ParentCategory: convertCategoryToTreeItem(ParentCategory),
                ChildCategories: ChildCategories.map(c => convertCategoryToTreeItem(c))
            })
        },
        getRelatedCategoriesFailed(state, action: PayloadAction<SystemError>) {
            state.relatedCategories = state.relatedCategories.toFailed(action.payload)
        },
        mutateRelatedCategories(state, action: PayloadAction<RelatedCategoriesMutation>) {
            const { CategoryId, ParentCategory, ChildCategories } = action.payload
            const currentRelatedCategories = state.relatedCategories.data

            if (!currentRelatedCategories) {
                return
            }

            state.relatedCategories = AsyncState.createSuccess({
                ...currentRelatedCategories,
                ...(CategoryId && { CategoryId }),
                ...(ParentCategory && { ParentCategory }),
                ...(ChildCategories && { ChildCategories })
            })
        },
        toggleCategoryExpanded(state, action: PayloadAction<ToggleCategoryPayload>) {
            const { CatalogId, CategoryId, IsExpanded } = action.payload
            state.catalogsMap[CatalogId] = mutateTree(state.catalogsMap[CatalogId], CategoryId, {
                isExpanded: IsExpanded
            })
        },
        updateCategoriesTree(state, action: PayloadAction<TreeData>) {
            state.catalogsMap[action.payload.rootId] = action.payload
        },
        moveCategoryProcess(state) {
            state.moveCategory = state.moveCategory.toProcess()
        },
        moveCategorySuccess(state, action: PayloadAction<MoveCategoryResponse>) {
            const { DraggableId, Mutations } = action.payload

            for (const mutation of Mutations) {
                const { Id, CatalogCode } = mutation
                const catalog = state.catalogsMap[CatalogCode]

                if (!catalog) {
                    continue
                }

                const existingItem = catalog.items[Id]

                if (!existingItem) {
                    continue
                }

                state.catalogsMap[CatalogCode] = mutateTree(
                    catalog,
                    Id,
                    categoryMutationToTreeItemMutation(mutation, existingItem)
                )
            }
            state.moveCategory = state.moveCategory.toSuccess(DraggableId)
        },
        moveCategoryFailed(state, action: PayloadAction<SystemError>) {
            state.moveCategory = state.moveCategory.toFailed(action.payload)
        },
        createCategoryProcess(state) {
            state.createCategory = state.createCategory.toProcess()
        },
        createCategorySuccess(state, action: PayloadAction<string>) {
            state.createCategory = state.createCategory.toSuccess(action.payload)
        },
        createCategoryFailed(state, action: PayloadAction<SystemError>) {
            state.createCategory = state.createCategory.toFailed(action.payload)
        },
        updateCategories(state, action: PayloadAction<CategoryMutation[]>) {
            for (const mutation of action.payload) {
                const { Id, CatalogCode, Status } = mutation
                const catalog = state.catalogsMap[CatalogCode]

                if (!catalog) {
                    continue
                }

                const existingItem = catalog.items[Id]

                if (existingItem) {
                    state.catalogsMap[CatalogCode] = mutateTreeItem(mutation, existingItem, catalog)
                } else {
                    // Do not insert category to tree if category has been removed
                    if (Status !== ArticleStatusType.Removed) {
                        state.catalogsMap[CatalogCode] = insertTreeItem(
                            createTreeItemFromCategoryMutation(mutation),
                            catalog
                        )
                    }
                }
            }

            const relatedCategories = state.relatedCategories.data
            state.relatedCategories = relatedCategories
                ? AsyncState.createSuccess(updateRelatedCategories(relatedCategories, action.payload))
                : AsyncState.create()
        },
        addCategory(state, action: PayloadAction<AddCategoryPayload>) {
            const { ParentItemId, Category } = action.payload
            const treeItem = convertCategoryToTreeItem(Category)
            const catalogId = treeItem.data.catalogCode
            state.catalogsMap[catalogId] = addChildrenCategories(ParentItemId, state.catalogsMap[catalogId], [Category])
        },
        createCatalogProcess(state) {
            state.createCatalog = state.createCatalog.toProcess()
        },
        createCatalogSuccess(state, action: PayloadAction<Catalog>) {
            state.createCatalog = state.createCatalog.toSuccess(action.payload)
            const tree = convertCatalogToTree(action.payload)
            if (!state.getCatalogs.data) {
                state.getCatalogs = state.getCatalogs.toSuccess([tree.rootId])
            } else {
                state.getCatalogs = state.getCatalogs.toSuccess([tree.rootId, ...state.getCatalogs.data])
            }
            state.catalogsMap[tree.rootId] = tree
        },
        createCatalogFailed(state, action: PayloadAction<SystemError>) {
            state.createCatalog = state.createCatalog.toFailed(action.payload)
        },
        updateCatalogProcess(state) {
            state.updateCatalog = state.updateCatalog.toProcess()
        },
        updateCatalogSuccess(state, action: PayloadAction<Catalog>) {
            state.updateCatalog = state.updateCatalog.toSuccess(action.payload)
            const { SymbolCode, Title, Permissions, PermittedAction, PermissionsWithNested } = action.payload
            const rootData = state.catalogsMap[SymbolCode].items[SymbolCode].data
            rootData.title = Title
            rootData.permissions = Permissions
            rootData.permittedAction = PermittedAction
            if ("permissionsWithNested" in rootData) {
                rootData.permissionsWithNested = PermissionsWithNested
            }
        },
        updateCatalogFailed(state, action: PayloadAction<SystemError>) {
            state.updateCatalog = state.updateCatalog.toFailed(action.payload)
        },
        deleteCatalogProcess(state) {
            state.deleteCatalog = state.deleteCatalog.toProcess()
        },
        deleteCatalogSuccess(state, action: PayloadAction<string>) {
            state.deleteCatalog = state.deleteCatalog.toSuccess(action.payload)
            delete state.catalogsMap[action.payload]
            if (state.getCatalogs.data) {
                state.getCatalogs = state.getCatalogs.toSuccess(
                    state.getCatalogs.data.filter(c => c !== action.payload)
                )
            }
        },
        deleteCatalogFailed(state, action: PayloadAction<SystemError>) {
            state.deleteCatalog = state.deleteCatalog.toFailed(action.payload)
        },
        shareCatalogBeforeProcess(state) {
            state.shareCatalog = state.shareCatalog.toBeforeProcess()
        },
        shareCatalogProcess(state) {
            state.shareCatalog = state.shareCatalog.toProcess()
        },
        shareCatalogSuccess(state, action: PayloadAction<string | undefined>) {
            state.shareCatalog = state.shareCatalog.toSuccess(action.payload)
        },
        removeCatalogSharingSuccess(state) {
            state.shareCatalog = state.shareCatalog.toSuccess(undefined)
        },
        shareCatalogFailed(state, action: PayloadAction<SystemError>) {
            state.shareCatalog = state.shareCatalog.toFailed(action.payload)
        },
        getArticleTypesProcess(state) {
            state.articleTypes = state.articleTypes.toProcess()
        },
        getArticleTypesSuccess(state, action: PayloadAction<GetArticleTypesResponse>) {
            state.articleTypes = state.articleTypes.toSuccess(action.payload)
        },
        getArticleTypesFailed(state, action: PayloadAction<SystemError>) {
            state.articleTypes = state.articleTypes.toFailed(action.payload)
        },
        createArticleTypeProcess(state) {
            state.createArticleType = state.createArticleType.toProcess()
        },
        createArticleTypeSuccess(state, action: PayloadAction<ArticleType>) {
            state.createArticleType = state.createArticleType.toSuccess()
            state.articleTypes = state.articleTypes.map(v => ({ Types: [...v.Types, action.payload] }))
        },
        createArticleTypeFailed(state, action: PayloadAction<SystemError>) {
            state.createArticleType = state.createArticleType.toFailed(action.payload)
        },
        updateArticleTypeProcess(state) {
            state.updateArticleType = state.updateArticleType.toProcess()
        },
        updateArticleTypeSuccess(state, action: PayloadAction<ArticleType>) {
            state.updateArticleType = state.updateArticleType.toSuccess()
            state.articleTypes = state.articleTypes.map(v => ({
                Types: v.Types.map(vv => (vv.Id === action.payload.Id ? action.payload : vv))
            }))
        },
        updateArticleTypeFailed(state, action: PayloadAction<SystemError>) {
            state.updateArticleType = state.updateArticleType.toFailed(action.payload)
        },
        deleteArticleTypeProcess(state) {
            state.deleteArticleType = state.deleteArticleType.toProcess()
        },
        deleteArticleTypeSuccess(state, action: PayloadAction<string>) {
            state.deleteArticleType = state.deleteArticleType.toSuccess()
            state.articleTypes = state.articleTypes.map(v => ({
                Types: v.Types.filter(vv => vv.Id !== action.payload)
            }))
        },
        deleteArticleTypeFailed(state, action: PayloadAction<SystemError>) {
            state.deleteArticleType = state.deleteArticleType.toFailed(action.payload)
        },
        setCurrentArticlesFilter(state, action: PayloadAction<ArticleFilterFormValues>) {
            state.currentArticlesFilter = action.payload
        },
        updateArticleUserViewsProcess(state) {
            state.articleUserViews = state.articleUserViews.toProcess()
        },
        updateArticleUserViewsSuccess(state, action: PayloadAction<ArticleUserViews>) {
            state.articleUserViews = state.articleUserViews.toSuccess(action.payload)
        },
        updateArticleUserViewsFailed(state, action: PayloadAction<SystemError>) {
            state.articleUserViews = state.articleUserViews.toFailed(action.payload)
        },
        updateCurrentAnswerById(state, action: PayloadAction<string>) {
            const selectedAnswerIndex = state.answersFrom.Answers.findIndex(a => a.Id === action.payload)
            if (selectedAnswerIndex !== undefined) {
                state.currentAnswer = state.answersFrom.Answers[selectedAnswerIndex]
            }
        },
        updateCurrentAnswer(state, action: PayloadAction<ArticleAnswer>) {
            const selectedAnswerIndex = state.answersFrom.Answers.findIndex(a => a.Id === action.payload.Id)
            if (selectedAnswerIndex !== undefined) {
                const answers = [...state.answersFrom.Answers]
                answers[selectedAnswerIndex] = action.payload
                state.answersFrom.Answers = answers
                state.currentAnswer = action.payload
            }
        },
        addAnswer(state, action: PayloadAction<ArticleAnswer>) {
            state.answersFrom.Answers = [...state.answersFrom.Answers, action.payload]
            state.currentAnswer = action.payload
        },
        updateArticleAnswersForm(state, action: PayloadAction<ArticleAnswersFormValues>) {
            state.answersFrom = action.payload
        },
        addArticleFormQuestions(state, action: PayloadAction<string[]>) {
            state.formQuestions = [...state.formQuestions, ...action.payload.map(toManualSampleQuestion)]
        },
        updateArticleFormQuestions(state, action: PayloadAction<ArticleQuestion[]>) {
            state.formQuestions = action.payload.map(toManualSampleQuestion)
        },
        removeArticleFormQuestions(state, action: PayloadAction<string[]>) {
            let updatedQuestions = [...state.formQuestions]
            updatedQuestions = updatedQuestions.filter(q => !action.payload.find(id => id === q.id))

            state.formQuestions = updatedQuestions
        },
        addArticleMarkedQuestions(state, action: PayloadAction<SampleQuestionType[]>) {
            state.markedMessages = action.payload
        },
        removeArticleMarkedQuestions(state, action: PayloadAction<string[]>) {
            state.markedMessagesReserve.RemovedIds = [...state.markedMessagesReserve.RemovedIds, ...action.payload]
            state.markedMessagesReserve.Questions = state.markedMessages
            state.markedMessagesReserve.Count = state.markedMessagesCount

            let updatedQuestions = [...state.markedMessages]
            updatedQuestions = updatedQuestions.filter(q => !action.payload.find(id => id === q.id))
            state.markedMessages = updatedQuestions
        },
        restoreArticleMarkedQuestions(state) {
            state.markedMessages = state.markedMessagesReserve.Questions
            state.markedMessagesCount = state.markedMessagesReserve.Count

            state.markedMessagesReserve.Questions = []
            state.markedMessagesReserve.Count = 0
            state.markedMessagesReserve.RemovedIds = []
        },
        setArticleMarkedQuestionsCount(state, action: PayloadAction<number>) {
            state.markedMessagesCount = action.payload
        },
        reduceArticleMarkedQuestionsCount(state, action: PayloadAction<number>) {
            state.markedMessagesCount = state.markedMessagesCount - action.payload
        },
        setArticleMarkedQuestionsReserve(state, action: PayloadAction<MarkedMessagesReserve>) {
            state.markedMessagesReserve = action.payload
        },
        getSharedTypeProcess(state) {
            state.sharedType = state.sharedType.toProcess()
        },
        getSharedTypeSuccess(state, action: PayloadAction<SharedType>) {
            state.sharedType = state.sharedType.toSuccess(action.payload)
        },
        getSharedTypeFailed(state, action: PayloadAction<SystemError>) {
            state.sharedType = state.sharedType.toFailed(action.payload)
        },
        updateEditedArticleExtId(state, action: PayloadAction<string>) {
            state.editedArticleExtId = action.payload
        },
        updateEditedArticleSurvey(state, action: PayloadAction<string[]>) {
            state.editedArticleSurvey = action.payload
        },
        setArticleExpanded(state, action: PayloadAction<boolean>) {
            state.articleExpanded = action.payload
        },
        setArticleFavoriteProcess(state) {
            state.articleFavorite = state.articleFavorite.toProcess()
        },
        setArticleFavoriteSuccess(state, action: PayloadAction<boolean>) {
            state.articleFavorite = state.articleFavorite.toSuccess(action.payload)
        },
        setArticleFavoriteFailed(state, action: PayloadAction<SystemError>) {
            state.articleFavorite = state.articleFavorite.toFailed(action.payload)
        },
        getArticleFavoriteBlockProcess(state) {
            state.articleFavoriteBlock = state.articleFavoriteBlock.toProcess()
        },
        getArticleFavoriteBlockSuccess(state, action: PayloadAction<FavoriteArticlesBlock>) {
            const tree = convertFavoriteArticleToTree(action.payload, state.articleFavoriteBlockExpanded)

            state.articleFavoriteBlock = state.articleFavoriteBlock.toSuccess(tree)
        },
        getArticleFavoriteBlockFailed(state, action: PayloadAction<SystemError>) {
            state.articleFavoriteBlock = state.articleFavoriteBlock.toFailed(action.payload)
        },
        updateArticleFavoriteBlockTree(state, action: PayloadAction<TreeData>) {
            state.articleFavoriteBlock = state.articleFavoriteBlock.toSuccess(action.payload)
        },
        expandArticleFavoriteBlock(state, action: PayloadAction<boolean>) {
            state.articleFavoriteBlockExpanded = action.payload
        },
        getArticleCompareSuccess(state, action: PayloadAction<ArticleCompareUpdateState>) {
            if (state.currentAnswer) {
                state.currentAnswer.Text = action.payload.text

                state.currentArticle = state.currentArticle.toSuccess({
                    Article: action.payload.Article.Article,
                    PermittedAction: action.payload.Article.PermittedAction,
                    IsLocked: action.payload.Article.IsLocked
                })
                state.answersFrom = { Answers: action.payload.Article.Article?.Answers ?? [] }
            }
        },
        moveFavoriteArticleProcess(state) {
            state.moveFavoriteArticle = state.moveFavoriteArticle.toProcess()
        },
        moveFavoriteArticleSuccess(state) {
            state.moveFavoriteArticle = state.moveFavoriteArticle.toSuccess()
        },
        moveFavoriteArticleFailed(state, action: PayloadAction<SystemError>) {
            state.moveFavoriteArticle = state.moveFavoriteArticle.toFailed(action.payload)
        },
        updateArticleInFavoriteBlock(state, action: PayloadAction<{ id: string; title: string }>) {
            const item = state.articleFavoriteBlock?.data?.items[action.payload.id]

            if (state.articleFavoriteBlock?.data && item) {
                const mutation: TreeItemMutation = {
                    ...item,
                    data: {
                        ...item.data,
                        title: action.payload.title
                    }
                }
                const updatedTree = mutateTree(state.articleFavoriteBlock?.data, item.id, mutation)

                state.articleFavoriteBlock = state.articleFavoriteBlock.toSuccess(updatedTree)
            }
        },
        setArticleHistoryMod(state, action: PayloadAction<boolean>) {
            state.isArticleHistoryMod = action.payload
        },
        setNewArticleFromState(state, action: PayloadAction<CurrentArticleState>) {
            if (state.currentArticle && state.currentAnswer) {
                state.currentAnswer.Text = action.payload.Article?.Answers
                    ? action.payload.Article?.Answers[0].Text
                    : ""
                state.currentArticle = state.currentArticle.toSuccess(action.payload)
                state.answersFrom = { Answers: action.payload.Article?.Answers ?? [] }
            }
        },
        removeArticleInFavoriteBlock(state, action: PayloadAction<string>) {
            const item = state.articleFavoriteBlock?.data?.items[action.payload]

            if (state.articleFavoriteBlock?.data && item) {
                const tree = removeItemFromParentChildren(state.articleFavoriteBlock?.data, item.id)

                delete tree.items[item.id]

                state.articleFavoriteBlock = state.articleFavoriteBlock.toSuccess(tree)
            }
        },
        setArticleActionButtonsActions(state, action: PayloadAction<ArticleActionButtonsActions>) {
            state.articleActionButtonsActions = action.payload
        },
        clearArticleActionButtonsActions(state) {
            state.articleActionButtonsActions = undefined
        },
        setArticleCompareMode(state, action: PayloadAction<ArticleCompareMode>) {
            state.articleCompareMode = action.payload
        },
        setOldArticleVersion(state, action: PayloadAction<CurrentArticleState>) {
            state.oldArticleVersion = action.payload
        },
        setPreviousVersionArticleId(state, action: PayloadAction<string | undefined>) {
            state.previousVersionArticleId = action.payload
        }
    },
    extraReducers(builder) {
        builder.addCase(resetReducerState, () => {
            return initialState
        })
    }
})

export const KnowledgeBaseReducer = knowledgeBase.reducer

export const {
    mutateCurrentBranch,
    clearCurrentBranch,
    clearCurrentArticle,
    setKnowledgeBaseWorkMode,
    setArticleWorkMode,
    setArticleLockStatus,
    setCurrentArticlesFilter,
    toggleCategoryExpanded,
    updateArticleProcess,
    getArticleProcess,
    updateArticleFavoriteBlockTree,
    ...actions
} = knowledgeBase.actions
