import { NewslineSettings, NewslineItem } from "../../../models/knowledgeBase/newsline"
import { cacher } from "../../../utility/common/rtkQueryCacheUtils"
import { queryApi } from "../../api"
import { apiInstance } from "../../api"
import { newslineTag } from "../../tags"

export const newslineController = {
    updateArticleSettings: (
        projectId: string,
        article: { SymbolCode: string; Id: string },
        settings: NewslineSettings
    ): Promise<void> => {
        return apiInstance.put(`${projectId}/newsline/${article.SymbolCode}/${article.Id}`, {
            ...settings,
            ArticleId: article.Id
        })
    }
}

export const newslineApi = queryApi.injectEndpoints({
    endpoints(build) {
        return {
            getNewsline: build.query<NewslineItem[], string>({
                query: projectId => ({
                    url: `${projectId}/newsline`,
                    method: "GET"
                }),
                providesTags(result, error, projectId) {
                    return cacher.cacheByIdArg(newslineTag)(result, error, projectId)
                }
            })
        }
    }
})

export const { useGetNewslineQuery } = newslineApi
