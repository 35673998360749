import React, { useContext } from "react"
import { useSelector } from "react-redux"
import { selectCatalogsState } from "../../store/knowledgeBase/selectors"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import Async from "../Async/Async"
import CatalogsLoader from "./CatalogsLoader"
import KnowledgeBaseContext from "../KnowledgeBaseProvider/KnowledgeBaseContext"
import EditableCatalog from "../Catalog/EditableCatalog/EditableCatalog"
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary"
import { ArticleStatusType } from "../../models/article"

export enum CatalogPlacement {
    knowledgeBase,
    workplace,
    massMailing
}

interface CatalogsProps {
    onEditCatalogClick?: () => void
    onCancelEditCatalogClick?: () => void
    onClickCategoryTitle?: (code: string) => void
    catalogPlacement: CatalogPlacement
}

const Catalogs: React.FC<CatalogsProps> = props => {
    const apiState = useSelector(selectCatalogsState)
    const { url, canModifyKnowledgeBase, onOpenSharingCatalog, projectId } = useContext(KnowledgeBaseContext)
    const isMassMailing = props.catalogPlacement === CatalogPlacement.massMailing

    return (
        <Async
            dataState={apiState}
            processView={<CatalogsLoader itemsCount={3} />}
            errorView={({ message }) => <ErrorMessage text={message} />}
        >
            {({ data: catalogIds }) => {
                return (
                    <ErrorBoundary local>
                        {projectId &&
                            catalogIds.map(id => (
                                <EditableCatalog
                                    key={`${id}-editable-catalog`}
                                    projectId={projectId}
                                    id={String(id)}
                                    url={url}
                                    canModifyKnowledgeBase={canModifyKnowledgeBase}
                                    onOpenSharingCatalog={onOpenSharingCatalog}
                                    {...props}
                                    isDragEnabled={!isMassMailing}
                                    availableStatuses={isMassMailing ? [ArticleStatusType.Active] : undefined}
                                />
                            ))}
                    </ErrorBoundary>
                )
            }}
        </Async>
    )
}

export default Catalogs
