import { Form, Formik } from "formik"
import { Button } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { formTranslation } from "../../locales/form"
import { NewslineFormValues, NewslineKeys, NewslineSettings } from "../../models/knowledgeBase/newsline"
import { updateArticleNewslineSettings } from "../../store/knowledgeBase/thunks/newsline"
import { saveNotificationSuccess } from "../../store/notifications/thunks"
import { selectCurrentProject } from "../../store/projects/selectors"
import { useAppSelector } from "../../store/hooks"
import { testId } from "../../utility/tests/testId"
import CheckBoxValidatableInput from "../CheckBoxValidatableInput/CheckBoxValidatableInput"
import { DatePickerInput } from "../DatePickerInput/DatePickerInput"
import InfoIcon from "../InfoIcon/InfoIcon"
import UserAccessForm from "../UserAccessForm/UserAccessForm"
import DebouncedValidatableInput from "../ValidatableInput/DebouncedValidatableInput"
import { getNewslineDefaultValues } from "./constants"
import styles from "./NewslineForm.module.scss"
import { NewslineFormSchema } from "./schemas/NewslineForm.schema"
import format from "date-fns/format"

const tNamespace = "newsline:form."

export interface NewslineFormProps {
    newsline?: NewslineSettings
}

export function NewslineForm({ newsline }: NewslineFormProps) {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const currentProject = useAppSelector(selectCurrentProject)
    const newslineValue = newsline ? newsline : getNewslineDefaultValues(currentProject?.id)

    const handleSubmit = (values: NewslineFormValues) => {
        dispatch(
            updateArticleNewslineSettings(values, () => {
                saveNotificationSuccess(dispatch, {
                    Title: {
                        Value: t(`${tNamespace}success`),
                        NeedLocalization: false
                    }
                })
            })
        )
    }

    return (
        <Formik<NewslineFormValues>
            initialValues={newslineValue}
            validationSchema={NewslineFormSchema}
            onSubmit={handleSubmit}
        >
            {formikProps => (
                <Form className={styles.form} onSubmit={formikProps.handleSubmit}>
                    <div className={styles.form__section}>
                        <CheckBoxValidatableInput
                            id={NewslineKeys.isInNewsline}
                            name={NewslineKeys.isInNewsline}
                            label={t(`${tNamespace}${NewslineKeys.isInNewsline}`)}
                            className={styles.form__checkbox}
                        />
                        <CheckBoxValidatableInput
                            id={NewslineKeys.isHideAfterReading}
                            name={NewslineKeys.isHideAfterReading}
                            label={t(`${tNamespace}${NewslineKeys.isHideAfterReading}`)}
                            className={styles.form__checkbox}
                        />
                        <CheckBoxValidatableInput
                            id={NewslineKeys.isEndless}
                            name={NewslineKeys.isEndless}
                            label={t(`${tNamespace}${NewslineKeys.isEndless}`)}
                            className={styles.form__checkbox}
                        />
                    </div>
                    <div className={styles.form__section}>
                        <DatePickerInput
                            name={NewslineKeys.lifeTime}
                            label={t(`${tNamespace}${NewslineKeys.lifeTime}`)}
                            value={
                                formikProps.values.LifeTime > 0
                                    ? format(formikProps.values.LifeTime, "dd.MM.yyyy HH:mm")
                                    : ""
                            }
                            onChange={(dates: Date[]) => {
                                formikProps.setFieldValue(NewslineKeys.lifeTime, dates[0].getTime(), true)
                            }}
                            onClear={() => {
                                formikProps.setFieldValue(NewslineKeys.lifeTime, 0)
                            }}
                            showTimeInput
                            minDate={new Date()}
                            disabled={formikProps.values.IsEndless}
                        />
                    </div>
                    <UserAccessForm
                        type="newsline"
                        title={t(`${tNamespace}${NewslineKeys.subscriptions}`)}
                        name={NewslineKeys.subscriptions}
                        currentProject={currentProject}
                        disableProjectsSelector
                        rolesPlaceholder={t(`${tNamespace}${NewslineKeys.subscriptions}-placeholder`)}
                        {...formikProps}
                    />
                    <div className={styles.form__section}>
                        <DebouncedValidatableInput
                            as="textarea"
                            id={NewslineKeys.description}
                            name={NewslineKeys.description}
                            label={t(`${tNamespace}${NewslineKeys.description}`)}
                            className={styles.form__textarea}
                            placeholder={t(formTranslation.enterText)}
                            icon={
                                <InfoIcon
                                    id="tooltipNewslineDescription"
                                    placement="top"
                                    content={t(`${tNamespace}${NewslineKeys.description}-tooltip`)}
                                />
                            }
                        />
                    </div>
                    <Button className={styles.form__btn} type="submit" data-testid={testId.saveNewsline}>
                        {t(formTranslation.save)}
                    </Button>
                </Form>
            )}
        </Formik>
    )
}
