import { useSelector } from "react-redux"
import { selectProjectSettings } from "../../store/projects/selectors"
import { useMemo } from "react"

const findValueInNestedObject = <T>(object: unknown, key: string): T | undefined => {
    if (typeof object !== "object" || object === null) {
        return undefined
    }

    if (key in object) {
        return object[key as keyof typeof object]
    }

    for (const nestedKey in object) {
        const nestedValue = findValueInNestedObject<T>(object[nestedKey as keyof typeof object], key)

        if (nestedValue !== undefined) {
            return nestedValue
        }
    }

    return undefined
}

export const useProjectSettingCheck = <T, K extends keyof T>(setting: K & string): T[K] | undefined => {
    const settings = useSelector(selectProjectSettings)

    return useMemo(() => findValueInNestedObject<T[K]>(settings, setting), [setting, settings])
}
