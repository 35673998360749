import React, { useContext, useEffect } from "react"
import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCommentAltDots, faCommentAltLines, IconDefinition } from "@fortawesome/pro-light-svg-icons"
import { useTranslation } from "react-i18next"
import styles from "./ArticleToolbar.module.scss"
import { useSelector, useDispatch } from "react-redux"
import { selectArticleMarkedMessagesCount, selectCurrentArticle } from "../../store/knowledgeBase/selectors"
import { RESULTS_PER_PAGE } from "../../utility/knowledgeBase/sampleQuestions"
import { setOperatorQuestionsCount } from "../../store/knowledgeBase/thunks"
import { testId } from "../../utility/tests/testId"
import KnowledgeBaseContext from "../KnowledgeBaseProvider/KnowledgeBaseContext"
import { QuestionComparisonResult } from "../../models/articleCompare"
import cn from "classnames"

const tNamespace = "knowledgeBase"

interface ArticleToolbarProps {
    isInWorkplace?: boolean
    answersCount?: number
    questionsCount?: number
    onOpenAnswers?: () => void
    onOpenQuestions?: () => void
    questionComparison?: QuestionComparisonResult
}

interface ToolbarItemProps {
    icon: IconDefinition
    itemsCount: number
    titleTranslation: string
    onClick?: () => void
    questionComparison?: QuestionComparisonResult
}

const ToolbarItem: React.FC<ToolbarItemProps> = props => {
    const { icon, itemsCount, titleTranslation, onClick, questionComparison } = props
    const { t } = useTranslation()
    //to-do универсально и для вариантов вопросов сделать
    let questionsCountAfterCompare = null

    if (questionComparison) {
        questionsCountAfterCompare =
            questionComparison.OldQuestionsCount -
            questionComparison.ReplacedQuestionsCount +
            questionComparison.AddedQuestionsCount
    }
    const isNothingToCompare =
        questionComparison?.OldQuestionsCount === 0 &&
        questionComparison?.ReplacedQuestionsCount === 0 &&
        questionComparison?.AddedQuestionsCount === 0

    const isEqual = questionsCountAfterCompare === questionComparison?.OldQuestionsCount

    return (
        <Button
            variant="link"
            className={cn(styles.articleToolbar__item, isEqual && !isNothingToCompare && styles.articleToolbar_removed)}
            onClick={onClick}
        >
            <FontAwesomeIcon icon={icon} size="1x" className={styles.articleToolbar__icon} />
            {`${t(`${tNamespace}:${titleTranslation}`)}`}
            {questionComparison && !isNothingToCompare && !isEqual ? (
                <>
                    <span className={styles.articleToolbar__number}>{`(${questionComparison.OldQuestionsCount})`}</span>
                    {questionsCountAfterCompare}
                </>
            ) : (
                <>{` (${isEqual ? questionsCountAfterCompare : itemsCount})`}</>
            )}
        </Button>
    )
}

const ArticleToolbar: React.FC<ArticleToolbarProps> = props => {
    const {
        answersCount = 0,
        questionsCount = 0,
        isInWorkplace,
        questionComparison,
        onOpenAnswers,
        onOpenQuestions
    } = props
    const dispatch = useDispatch()
    const currentArticle = useSelector(selectCurrentArticle)
    const markedMessagesCount = useSelector(selectArticleMarkedMessagesCount)
    const { shareId } = useContext(KnowledgeBaseContext)
    const questionsSum = questionsCount + markedMessagesCount

    const showToolbarItem = (count: number) => {
        return isInWorkplace ? count > 1 : true
    }

    useEffect(() => {
        const symbolCode = currentArticle?.Article?.SymbolCode ?? ""
        !shareId && setOperatorQuestionsCount(symbolCode, RESULTS_PER_PAGE, dispatch)
    }, [shareId])

    return (
        <div className={styles.articleToolbar} data-testid={testId.articleToolbar}>
            {onOpenAnswers && showToolbarItem(answersCount) && (
                <ToolbarItem
                    icon={faCommentAltDots}
                    itemsCount={answersCount}
                    titleTranslation="answer-options"
                    onClick={onOpenAnswers}
                />
            )}
            {onOpenQuestions && showToolbarItem(questionsSum) && (
                <ToolbarItem
                    icon={faCommentAltLines}
                    itemsCount={questionsSum}
                    titleTranslation="question-samples"
                    onClick={onOpenQuestions}
                    questionComparison={questionComparison}
                />
            )}
        </div>
    )
}

export default ArticleToolbar
