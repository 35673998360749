import { refetchNewsline } from "../../../api/controllers/knowledgeBase/helpers"
import { newslineController } from "../../../api/controllers/knowledgeBase/newsline"
import { NewslineSettings } from "../../../models/knowledgeBase/newsline"
import { ActionResult, Dispatch } from "../../../utility/common/storeHelper"
import { handleHttpExceptionWithoutAction } from "../../handleHttpException"
import { selectCurrentProjectId } from "../../projects/selectors"
import store from "../../store"
import * as constants from "../constants"
import { selectCatalogsMap, selectCurrentArticle } from "../selectors"
import { actions } from "../slice"
import knowledgeBaseController from "./../../../api/controllers/knowledgeBase/knowledgeBase"

export const toggleCatalogNewsline =
    (projectId: string, catalogCode: string, callbackFn?: () => void): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        try {
            const catalogsMap = selectCatalogsMap(store.getState())

            if (catalogCode in catalogsMap) {
                const catalog = catalogsMap[catalogCode]
                const root = catalog.items[catalog.rootId]

                await knowledgeBaseController.updateCatalog(projectId, catalogCode, {
                    WithNested: true,
                    IsNewslineActive: !root.data.newsline
                })

                dispatch(
                    actions.updateCatalogsMapItem({
                        catalogId: catalogCode,
                        treeItemId: catalog.rootId,
                        item: {
                            ...root,
                            data: {
                                ...root.data,
                                newsline: !root.data.newsline
                            }
                        }
                    })
                )
                dispatch(refetchNewsline(projectId))

                if (callbackFn) {
                    callbackFn()
                }
            }
        } catch (e) {
            handleHttpExceptionWithoutAction(e, constants.TOGGLE_CATALOG_NEWSLINE_FAILED_MESSAGE, dispatch)
        }
    }

export const updateArticleNewslineSettings =
    (newsline: NewslineSettings, callbackFn?: () => void): ActionResult<Promise<void>> =>
    async (dispatch: Dispatch) => {
        try {
            const article = selectCurrentArticle(store.getState())
            const projectId = selectCurrentProjectId(store.getState())

            if (projectId && article && article.Article) {
                await newslineController.updateArticleSettings(
                    projectId,
                    { Id: article.Article.Id, SymbolCode: article.Article.SymbolCode },
                    newsline
                )

                dispatch(
                    actions.getArticleSuccess({
                        ...article,
                        Article: {
                            ...article.Article,
                            NewslineSettigsDTO: newsline
                        }
                    })
                )

                if (callbackFn) {
                    callbackFn()
                }
            } else {
                throw new Error()
            }
        } catch (e) {
            handleHttpExceptionWithoutAction(e, constants.UPDATE_ARTICLE_NEWSLINE_SETTINGS_FAILED_MESSAGE, dispatch)
        }
    }
