import React, { ReactNode, useCallback } from "react"
import { Image } from "react-bootstrap"
import cn from "classnames"
import styles from "./UserCard.module.scss"
import { ClassProps } from "../../utility/common/props"
import { testId } from "../../utility/tests/testId"
import TooltipTrigger from "../TooltipTrigger/TooltipTrigger"
import { useTranslation } from "react-i18next"
import { colorByName } from "../../helpers/color"
import { generateFullName } from "../../utility/common/generateFullName"

const tNamespace = "dialogs:info."
const pictureSizes = ["small", "normal", "large"]

export interface UserCardProps extends ClassProps {
    firstName?: string
    lastName?: string
    middleName?: string
    picture?: string
    isShort?: boolean
    onClick?: () => void
    indicator?: ReactNode
    userCardMaxLength?: number
    infoClass?: string
    isClient?: boolean
    isNewWorkplace?: boolean
    inactive?: boolean
    inHeader?: boolean
    isLargeAvatar?: boolean
    isLargeName?: boolean
}

const tooltipId = "user-card-tooltip"
const tooltipDelay = { show: 450, hide: 0 }

const UserCard: React.FC<UserCardProps> = props => {
    const {
        firstName,
        lastName,
        middleName,
        picture,
        isShort,
        onClick,
        indicator,
        userCardMaxLength = 15,
        infoClass,
        isClient,
        isNewWorkplace,
        inactive,
        inHeader,
        isLargeAvatar = false,
        isLargeName = false
    } = props
    const { t } = useTranslation()

    const pictureSizeClass = pictureSizes[Number(!inHeader) + Number(isLargeAvatar)]

    const unknownClientFullName = `${t(`${tNamespace}unknown-client-firstname`)} ${t(
        `${tNamespace}unknown-client-lastname`
    )}`

    const fullName = generateFullName(firstName, lastName, middleName, isClient ? unknownClientFullName : "")

    const getLetters = useCallback(() => {
        let resultStr = ""

        if (lastName && lastName.length) {
            resultStr += lastName[0]
        }

        if (firstName && firstName.length) {
            resultStr += firstName[0]
        }

        if (resultStr.length < 1 && middleName && middleName.length) {
            resultStr += middleName[0]
        }

        if (resultStr.length || !isClient) {
            return resultStr
        }

        return t(`${tNamespace}unknown-client-firstname`)[0] + t(`${tNamespace}unknown-client-lastname`)[0]
    }, [firstName, lastName, middleName, isClient, t])

    const renderUserCardInfo = () => {
        if (isShort) {
            return null
        }

        return (
            <div className={cn(infoClass, styles.userCard__info)}>
                <TooltipTrigger
                    id={tooltipId}
                    placement="top"
                    content={fullName}
                    condition={!inHeader && fullName.length > userCardMaxLength}
                    delay={tooltipDelay}
                >
                    {isNewWorkplace ? (
                        <span
                            className={cn(
                                styles.userCard__clientFullName,
                                isLargeName && styles.userCard__clientFullName_large
                            )}
                        >
                            {fullName}
                        </span>
                    ) : (
                        <div className={styles.userCard__introduction}>
                            <div className={styles.userCard__firstName}>{lastName}</div>
                            <div className={styles.userCard__lastName}>{firstName}</div>
                        </div>
                    )}
                </TooltipTrigger>
                {props.children}
            </div>
        )
    }

    return (
        <div
            className={cn(
                styles.userCard,
                props.className,
                isShort && styles.userCard_short,
                onClick && styles.userCard_clickable
            )}
            onClick={onClick}
            data-test-id={testId.userCard}
        >
            <div className={styles.userCard__pictureContainer}>
                {picture ? (
                    <Image
                        className={cn(
                            styles.userCard__picture,
                            styles[`userCard__picture_${pictureSizeClass}`],
                            inactive && styles.userCard__picture_inactive
                        )}
                        src={picture}
                    />
                ) : (
                    <div
                        className={cn(
                            styles.userCard__picture,
                            styles[`userCard__picture_${pictureSizeClass}`],
                            styles.userCard__picture_default
                        )}
                        style={{ backgroundColor: !inactive ? colorByName(fullName) : "#E0E0E0" }}
                    >
                        {getLetters()}
                    </div>
                )}
                {indicator && <div className={styles.userCard__indicator}>{indicator}</div>}
            </div>
            {renderUserCardInfo()}
        </div>
    )
}

export default UserCard
